import {useState} from 'react';
// import Clipboard from '@react-native-clipboard/clipboard';
const imgOjoAbierto = require('./icon-eye.svg');
const imgOjoCerrado = require('./icon-eye-slash.svg');
const imgCopy = require('./icon-copy.svg');

export const useInputText = (
  name,
  value,
  keyboardTypePassword,
  secureTextEntry,
) => {
  const TOP_FOCUS = '-8px';
  const TOP_DEFAULT = '23px';
  const [ojo, setOjo] = useState(imgOjoAbierto);
  const [iconCopy, setIconCopy] = useState(imgCopy);
  const [secure, setSecure] = useState(secureTextEntry);
  const [focus, setFocus] = useState(false);

  let top = 0;
  const topFocus = '-11px';
  const topBlur = '20px';

  const showPass = () => {
    setSecure(!secure);
    //console.log('showPass => ', secure);
    secure ? setOjo(imgOjoCerrado) : setOjo(imgOjoAbierto);
  };
  const onCopyValue = () => {
    // Clipboard.setString(value);
  };

  const onFocus = () => {
    // console.log('onFocus => ', name);
    top = topFocus;
    setFocus(true);
    moveLabel(true);
  };

  const onBlur = () => {
    // console.log('onBlur => name =>', name);
    // console.log('onBlur => value =>', value);
    top = topBlur
    setFocus(false);
    if (!value) {
      moveLabel(false);
    }
  };

  const moveLabel = (f) => {
    // console.log('moveLabel ');
    const newTop = f || value ? TOP_FOCUS : TOP_DEFAULT;
    //setTop(paddingTop);
  };

  return {
    top,
    topFocus,
    topBlur,
    ojo,
    iconCopy,
    secure,
    focus,
    showPass,
    onCopyValue,
    onFocus,
    onBlur,
  };
};
