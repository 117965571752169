import styled, { createGlobalStyle } from 'styled-components'
import {GlobalColors, GlobalColorsInputText} from '../../../styles/appColors';
import {GlobalFonts} from '../../../styles/GlobalStyles';

export const GlobalStylesInputTextMyS = createGlobalStyle`
  :root {
    --size-bezel: .5rem;
  }
  
  .input-text {
    width: 100%;
    height: ${props => props.height};
    margin-bottom: ${props => props.marginBottom};
    padding: 0px ${props => props.paddingHorizontal};
    border: 2px solid ${props => props.borderColor ? props.borderColor : GlobalColorsInputText.labelFocus};
    border-radius: 10px;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    flex-direction: column !important;
    
    &__label {
      position: absolute;
      height: 34px;
      padding: 0 12px;
      margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * .5);
      //background-color: ${props => props.backgroundColor ? props.backgroundColor : GlobalColorsInputText.primaryBg};
      background-color: transparent;
      font: 16px ${GlobalFonts.fontPrimary};
      color: ${GlobalColorsInputText.label};
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      transition: transform 120ms ease-in;
      display: flex;
      align-items: center;
    }
    &__field {
      box-sizing: border-box;
      display: block;
      width: 100%;
      height: ${props => props.height};
      //padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
      padding: 10px 15px;
      font: ${props => props.fontSize} ${GlobalFonts.fontPrimary};
      text-align: ${props => props.textAlign};
      color: ${props => props.fontColor ? props => props.fontColor : GlobalColors.text};
      background: transparent;
      border: none !important;
      
      &:focus,
      &:not(:placeholder-shown) {
        & + .input-text__label {
          padding: 0 12px;
          background-color: ${props => props.backgroundColor ? props.backgroundColor : GlobalColorsInputText.primaryBg};
          //transform: translate(.25rem, -65%) scale(.8);
          transform: translate(.25rem, -82%) scale(1);
        }
      }
    }
  }
`
export const ContInputText = styled.div`
  width: 100%;
  height: ${props => props.height};
  margin-bottom: ${props => props.marginBottom};
  border: none !important;
`
export const Label = styled.label`
  background: ${props => props.backgroundColor ? props.backgroundColor : GlobalColorsInputText.primaryBg};
`
export const Text = styled.div`
  font: 16px ${GlobalFonts.fontPrimary};
  text-align: left;
  color: ${GlobalColorsInputText.label};
`
export const ContInput = styled.div`
  width: 100%;
`
export const InputText = styled.input`
  //position: absolute;
  width: 100%;
  height: 54px;
  background-color: transparent !important;
  border: none !important;
  font: ${props => props.fontSize} ${GlobalFonts.fontPrimary};
  text-align: ${props => props.textAlign};
  color: ${GlobalColorsInputText.color};
`
export const InputEye = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 12px;
  right: 0;
`
export const EyeFocus = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 12px;
  right: 0;
`
export const ContIconCopy = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 20px;
  right: 0;
`
export const ErrorText = styled.div`
  position: relative;
  bottom: 0px;
  margin-left: 4px;
  font: 12px ${GlobalFonts.fontPrimary};
  color: ${GlobalColorsInputText.errorText};
`

