import React, {useEffect, useState} from 'react';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import InputMask from "react-input-mask";
import {InputTextMySFormik} from '../ui/InputTextMyS';
import {CheckboxMySFormik} from '../ui/CheckboxMyS';
import {SelectMySFormik} from '../ui/SelectMyS';
import {InputTextMySCreditCardFormik} from '../ui/InputTextMyS/InputTextMySCreditCard';
import {ContInputText, ErrorText, GlobalStylesInputTextMyS, Label} from '../ui/InputTextMyS/styles';
import {GlobalColors, GlobalColorsInputText} from '../../styles/appColors';

export const FormTest = () => {
  const [paymentMethod, setPaymentMethod] = useState({
    nombre: '',
    cardname: '',
    test: false,
    combo: '',
  });
  const dataMonth = [];
  for ( let x = 1; x <= 12; x++ ) {
    dataMonth.push({
      label: x.toString() < 10 ? `0${x.toString()}` : x.toString(),
      value: x.toString() < 10 ? `0${x.toString()}` : x.toString(),
    });
  }

  let max = 4;

  const validationSchema = Yup.object({
    nombre: Yup.string().required('Tiene que escribir un nombre'),
    // cardname: Yup.string().required('Tiene que escribir un nombre'),
    cardname: Yup.string()
      .test("len", "Invalid Card No.", (val = "") => {
        const val_length_without_dashes = val.replace(/-|_/g, "").length;
        return val_length_without_dashes === 19;
      })
      .required("Card No. is required"),
    cardnumber: Yup.string()
      .test("len", "Invalid Card No.", (val = "") => {
        const val_length_without_dashes = val.replace(/-|_/g, "").length;
        return val_length_without_dashes === 19;
      })
      .required("Card No. is required"),
    number: Yup.string()
    .test("len", "Invalid Card No.", (val = "") => {
      const val_length_without_dashes = val.replace(/-|_/g, "").length;
      return val_length_without_dashes === 19;
    })
    .required("Card No. is required"),
    phone: Yup.string()
      .test("len", "Invalid Tel No.", (val = "") => {
        const val_length_without_dashes = val.replace(/-|_/g, "").length;
        return val_length_without_dashes === 12;
      })
      .required("Tel No. is required"),
    test: Yup.boolean().required('Tiene que escribir un nombre'),
    combo: Yup.string().required('Seleccione un item'),
  });
  const initialValues = {
    nombre: '',
    cardname: '',
    cardnumber: '',
    number: '',
    test: '',
    combo: '',
    phone: '',
  };
  const onChangeNombre = (val) => {
    console.log('onChangeNombre => values =>', val);
    setPaymentMethod({
      ...paymentMethod,
      nombre: val,
    });
  };
  const onChangeName = (val) => {
    console.log('onChangeName => values =>', val);
    setPaymentMethod({
      ...paymentMethod,
      cardname: val,
    });
  };
  const onChangeTest = (val) => {
    console.log('onChangeTest => values =>', val);
    setPaymentMethod({
      ...paymentMethod,
      test: val,
    });
  };
  const onChangeCombo = (val) => {
    console.log('onChangeCombo => values =>', val);
    setPaymentMethod({
      ...paymentMethod,
      combo: val,
    });
  };
  const onSubmit = (values) => {
    // alert(JSON.stringify(values, null, 2));
    console.log('onSubmit => paymentMethod =>', paymentMethod);
    console.log('onSubmit => values =>', values);
  };

  useEffect(() => {
    console.log('paymentMethod =>', paymentMethod);
  }, [paymentMethod]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, {resetForm}) => {
          await onSubmit(values);
          resetForm();
        }}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldTouched,
            setFieldValue,
          } = props;

          // console.log('values =>', values);
          console.log('touched =>', touched);
          console.log('errors =>', errors);
          // console.log('values.test =>', values.test);

          return (
            <div>
              <Form>
                <div style={{width: '50%', float: 'right'}}>
                  <div>
                    <InputTextMySFormik
                      name={'nombre'}
                      label={'Nombre'}
                      value={paymentMethod.nombre}
                      onChange={e => onChangeNombre(e.target.value)}
                      autoFocus={false}
                      readOnly={false}
                    />
                  </div>

                  <div>
                    <InputMask
                      mask="9999 9999 9999 9999"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.number}
                    >
                      {() => (
                        <ContInputText
                          height={'56px'}
                          marginBottom={'30px'}
                          paddingHorizontal={'12px'}
                        >
                          <GlobalStylesInputTextMyS
                            height={'56px'}
                            borderColor={GlobalColorsInputText.labelFocus}
                            fontSize={'16px'}
                            fontColor={GlobalColors.text}
                            color={GlobalColors.text}
                            textAlign={'left'}
                          />
                          <Label className="input-text" backgroundColor={GlobalColorsInputText.primaryBg}>
                            <input
                              className="input-text__field"
                              name={'number'}
                            />
                            <span className="input-text__label">{''}{'Nombre de la tarjeta'}{''}</span>
                          </Label>
                          {touched.number && errors.number
                            ? <ErrorText>{errors.number}</ErrorText>
                            : null
                          }
                        </ContInputText>
                      )}
                    </InputMask>
                  </div>

                  <div>
                    <InputMask
                      mask="9999 9999 9999 9999"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cardname}
                    >
                      {() => (
                        <InputTextMySCreditCardFormik
                          name={'cardname'}
                          label={'Nombre de la tarjeta'}
                          props={props}
                        />
                        /*<ContInputText
                          height={'56px'}
                          marginBottom={'30px'}
                          paddingHorizontal={'12px'}
                        >
                          <GlobalStylesInputTextMyS
                            height={'56px'}
                            borderColor={GlobalColorsInputText.labelFocus}
                            fontSize={'16px'}
                            fontColor={GlobalColors.text}
                            color={GlobalColors.text}
                            textAlign={'left'}
                          />
                          <Label className="input-text" backgroundColor={GlobalColorsInputText.primaryBg}>
                            <input
                              className="input-text__field"
                              name={'cardname'}
                            />
                            <span className="input-text__label">{''}{'Nombre de la tarjeta'}{''}</span>
                          </Label>
                          {meta.touched && meta.error
                            ? <ErrorText>{meta.error}</ErrorText>
                            : null
                          }
                        </ContInputText>*/
                      )}
                    </InputMask>
                  </div>

                  <div>
                    <InputMask
                      mask="9999 9999 9999 9999"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cardnumber}
                    >
                      {() => (
                        <div>
                          <input
                            name={'cardnumber'}
                          />
                          {touched.cardnumber && errors.cardnumber
                            ? <ErrorText>{errors.cardnumber}</ErrorText>
                            : null
                          }
                        </div>
                      )}
                    </InputMask>
                  </div>

                  <div>
                    <InputMask
                      mask="99-999999999-9"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                    >
                      {() => (
                        <input
                          name={'phone'}
                          label={'Teléfono'}
                        />
                      )}
                    </InputMask>
                  </div>

                  <div>
                    <CheckboxMySFormik
                      name={'test'}
                      label={'Test'}
                      onChange={e => onChangeTest(e.target.value)}
                    />
                  </div>

                  <div>
                    <SelectMySFormik
                      name={'combo'}
                      label={'Combo Box'}
                      dataItems={dataMonth}
                    >
                      <option value="" hidden selected/>
                      <option value="felipe">Felipe</option>
                      <option value="chanchitofeliz">Chanchito Feliz</option>
                      <option value="chanchitotriste">Chanchito Triste</option>
                    </SelectMySFormik>
                  </div>


                  <button type="submit" className="button is-primary">
                    Submit
                  </button>
                  <br/>
                  <br/>
                  <br/>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};
