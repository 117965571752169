import React, {useEffect} from 'react';
import { useField } from 'formik';
import {
  ContInputText,
  ErrorText,
  GlobalStylesInputTextMyS, Label,
} from './styles';
import {useInputText} from './useInputText';
import {GlobalColors, GlobalColorsInputText} from '../../../styles/appColors';

export const InputTextMySFormik = ({
  label,
  value,
  type = 'text',
  onChange,
  height = '56px',
  backgroundColor = GlobalColorsInputText.primaryBg,
  borderColor = GlobalColorsInputText.labelFocus,
  fontSize = '16px',
  fontColor = GlobalColors.text,
  paddingHorizontal = '12px',
  textAlign = '',
  showLabel = true,
  labelColor = GlobalColorsInputText.labelFocus,
  autoFocus = false,
  forceFocus = false,
  maxLength,
  readOnly = true,
  copyValue = false,
  ...props
}) => {
  const [field, meta] = useField(props);
  // console.log('props =>', props);
  // const {
  //   showPass,
  //   onCopyValue,
  //   onFocus,
  //   onBlur,
  //   top,
  //   topFocus,
  //   topBlur,
  //   ojo,
  //   iconCopy,
  //   secure,
  //   focus,
  // } = useInputText(
  //   props.name,
  //   value,
  // );

  // useEffect(() => {
  //   // console.log('InputTextMyS -> value ->', value);
  //   if (forceFocus) {
  //     onFocus();
  //   }
  // }, []);

  // useEffect(() => {
  //   // console.log('useEffect => meta.touched =>', meta.touched);
  //   // console.log('useEffect => meta.error =>', meta.error);
  //   // console.log('useEffect => meta.value =>', meta.value);
  //   if (meta.value === '') {
  //     onBlur();
  //   }
  // }, [meta.value]);

  // console.log('focus =>', focus);
  // console.log('field =>', field);
  // console.log('meta =>', meta);

  // console.log('field.onChange ->', field.onChange);

    {/*
  return (
    <ContInputText
      className={'input'}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      height={height}
      marginBottom={'16px'}
      paddingHorizontal={paddingHorizontal}
    >
      <GlobalStylesInputTextMyS/>
      {showLabel && (
        <Label
          className={'input__label'}
          // bg={focus || value ? GlobalBackgroundColors.bgPrimary : 'transparent'}
          // translateY={focus || value ? topFocus : topBlur}
          onClick={() => {
            document.getElementById(props.name).focus();
          }}
        >
          <Text>{' '}{label}{' '}</Text>
        </Label>
      )}
      <ContInput>
        <InputText
          className={'input__field'}
          fontSize={fontSize}
          color={fontColor}
          textAlign={textAlign ? textAlign : 'left'}
          value={value}
          autoFocus={autoFocus}
          maxLength={maxLength}
          readOnly={readOnly}
          onFocus={() => onFocus()}
          onBlur={() => onBlur()}
          {...field}
          {...props}
        />
      </ContInput>
      {meta.touched && meta.error
        ? <ErrorText>{meta.error}</ErrorText>
        : null
      }
    </ContInputText>
  );
    */}

  return (
    <ContInputText
      height={height}
      marginBottom={'30px'}
      paddingHorizontal={paddingHorizontal}
    >
      <GlobalStylesInputTextMyS
        height={height}
        borderColor={borderColor}
        fontSize={fontSize}
        fontColor={fontColor}
        color={fontColor}
        textAlign={textAlign ? textAlign : 'left'}
      />
      <Label className="input-text" backgroundColor={backgroundColor}>
        <input
          className="input-text__field"
          type={type}
          placeholder=" "
          value={value}
          onChange={props.onChange}
          {...field}
          {...props}
        />
        <span className="input-text__label">{''}{label}{''}</span>
      </Label>
      {meta.touched && meta.error
        ? <ErrorText>{meta.error}</ErrorText>
        : null
      }
    </ContInputText>
  )
};

/*
<div className="control">
  <label className="label" htmlFor={props.name}>{label}</label>
  <input className="input" {...field} {...props}/>
  {meta.touched && meta.error
    ? <div className="error">{meta.error}</div>
    : null
  }
</div>
 */
