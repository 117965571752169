import * as React from "react"
import Layout from '../components/Layout'
import {FormTest} from '../components/FormTest';

const FormikPage = () => {

  return (
    <Layout clsOverflow={false}>
      <FormTest/>
    </Layout>
  )
}

export default FormikPage;
