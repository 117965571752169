import React from 'react';
import { useField } from 'formik';
import {ContCheckboxMyS, Input, Label, Span, ErrorText} from './styles';
import {GlobalBackgroundColors, GlobalColors} from '../../../styles/GlobalStyles';

export const CheckboxMySFormik = ({
  label = '',
  // checked = false,
  onChange,
  fontColor = GlobalColors.colorText,
  borderColor = GlobalBackgroundColors.bgTertiary,
  bgColorChecked = GlobalBackgroundColors.bgTertiary,
  ...props
}) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' })

  return (
    <ContCheckboxMyS>
      <Label color={fontColor} bgColorChecked={bgColorChecked}>
        <Input
          type="checkbox"
          // checked={checked}
          // onChange={() => onChange()}
          {...field}
          {...props}
        />
        <Span borderColor={borderColor}/>
        {label}
      </Label>
      {meta.touched && meta.error
        ? <ErrorText>{meta.error}</ErrorText>
        : null
      }
    </ContCheckboxMyS>
  );
};
