import React, {useEffect} from 'react';
import { useField } from 'formik';
import InputMask from "react-input-mask";
import {
  ContInputText,
  ErrorText,
  GlobalStylesInputTextMyS, Label,
} from './styles';
import {useInputText} from './useInputText';
import {GlobalColors, GlobalColorsInputText} from '../../../styles/appColors';

export const InputTextMySCreditCardFormik = ({
  name,
  label,
  value,
  onChange,
  height = '56px',
  backgroundColor = GlobalColorsInputText.primaryBg,
  borderColor = GlobalColorsInputText.labelFocus,
  fontSize = '16px',
  fontColor = GlobalColors.text,
  paddingHorizontal = '12px',
  textAlign = '',
  showLabel = true,
  labelColor = GlobalColorsInputText.labelFocus,
  autoFocus = false,
  forceFocus = false,
  ...props
}) => {
  const [field, meta] = useField(props);
  console.log('props =>', props);
  // console.log('field =>', field);
  // console.log('meta =>', meta);

  return (
    <ContInputText
      height={height}
      marginBottom={'30px'}
      paddingHorizontal={paddingHorizontal}
    >
      <GlobalStylesInputTextMyS
        height={height}
        borderColor={borderColor}
        fontSize={fontSize}
        fontColor={fontColor}
        color={fontColor}
        textAlign={textAlign ? textAlign : 'left'}
      />
      <Label className="input-text" backgroundColor={backgroundColor}>
        <input
          className="input-text__field"
          name={name}
        />
        <span className="input-text__label">{''}{label}{''}</span>
      </Label>
      {/*{meta.touched && meta.error*/}
      {/*  ? <ErrorText>{meta.error}</ErrorText>*/}
      {/*  : null*/}
      {/*}*/}
    </ContInputText>
  )
};

/*
<div className="control">
  <label className="label" htmlFor={props.name}>{label}</label>
  <input className="input" {...field} {...props}/>
  {meta.touched && meta.error
    ? <div className="error">{meta.error}</div>
    : null
  }
</div>
 */
