import React from 'react';
import { useField } from 'formik';
import {ContSelect, ErrorText, Label, Select, GlobalStylesSelectMyS} from './stylesSelect';
import {GlobalColors, GlobalColorsInputText} from '../../../styles/appColors';

export const SelectMySFormik = ({
  label,
  value,
  dataItems,
  onChange,
  readOnly,
  // height = '56px',
  height = '70px',
  backgroundColor = GlobalColorsInputText.primaryBg,
  borderColor = GlobalColorsInputText.labelFocus,
  fontSize = '16px',
  fontColor = GlobalColors.text,
  paddingHorizontal = '12px',
  textAlign = '',
  labelColor = GlobalColorsInputText.labelFocus,
  autoFocus = false,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <ContSelect
      height={height}
      marginBottom={'30px'}
      paddingHorizontal={paddingHorizontal}
    >
      <GlobalStylesSelectMyS
        height={height}
        borderColor={borderColor}
        fontSize={fontSize}
        fontColor={fontColor}
        color={fontColor}
        textAlign={textAlign ? textAlign : 'left'}
      />
      <Label className="select-text" backgroundColor={backgroundColor}>
        <Select
          // required
          className="select-text__field"
          {...field}
          {...props}
        >
          <option value="" hidden selected>{''}</option>
          {dataItems.map(item => {
            return <option value={item.value} key={item.value} >{item.label}</option>
          })}
        </Select>
        <span className="select-text__label">{''}{label}{''}</span>
      </Label>
      {meta.touched && meta.error
        ? <ErrorText>{meta.error}</ErrorText>
        : null
      }
    </ContSelect>
  );
};
